export default function Example() {
    return (
        <div className="px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">Our Products</h2>
                <p className="mt-6 text-lg leading-8 text-gray-700">
                    Foreign imports, high-end products, quality guaranteed. Everyone has a different body shape, and each of our medical products is tailored to fit perfectly. Meeting your needs and addressing the problems you face is our responsibility.
                </p>
            </div>
        </div>
    )
}

