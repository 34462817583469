import { Fragment, useState } from 'react'
import { Tab } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  let [beforeafter] = useState({
    first_girl: [
      {
        id: 1,
        source: "websites/before_after/1_girl/ba/first.jpg"
      },
    ],
    b_girl: [
      {
        id: 1,
        source: "websites/before_after/b_girl/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/b_girl/ba/second.jpg"
      },
      {
        id: 3,
        source: "websites/before_after/b_girl/ba/third.jpg"
      },
    ],

    c_girl: [
      {
        id: 1,
        source: "websites/before_after/c_girl/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/c_girl/ba/second.jpg"
      },
      {
        id: 3,
        source: "websites/before_after/c_girl/ba/third.jpg"
      },
    ],

    d_boy: [
      {
        id: 1,
        source: "websites/before_after/d_boy/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/d_boy/ba/second.jpg"
      },
      {
        id: 3,
        source: "websites/before_after/d_boy/ba/third.jpg"
      },
    ],

    l_girl: [
      {
        id: 1,
        source: "websites/before_after/l_girl/ba/first.jpg"
      },

    ],

    l_girltwo: [
      {
        id: 1,
        source: "websites/before_after/l_girl2/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/l_girl2/ba/second.jpg"
      },

    ],

    s_girl: [
      {
        id: 1,
        source: "websites/before_after/s_girl/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/s_girl/ba/second.jpg"
      },
      {
        id: 3,
        source: "websites/before_after/s_girl/ba/third.jpg"
      },
    ],

    t_boy: [
      {
        id: 1,
        source: "websites/before_after/t_boy/ba/first.jpg"
      },
      {
        id: 2,
        source: "websites/before_after/t_boy/ba/second.jpg"
      },
      {
        id: 3,
        source: "websites/before_after/t_boy/ba/third.jpg"
      },
    ],


  })

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  let [isOpenTwo, setIsOpenTwo] = useState(false)

  function closeModalTwo() {
    setIsOpenTwo(false)
  }

  let [isOpenThree, setIsOpenThree] = useState(false)

  function closeModalThree() {
    setIsOpenThree(false)
  }

  let [isOpenFour, setIsOpenFour] = useState(false)

  function closeModalFour() {
    setIsOpenFour(false)
  }

  let [isOpenFive, setIsOpenFive] = useState(false)

  function closeModalFive() {
    setIsOpenFive(false)
  }

  let [isOpenSix, setIsOpenSix] = useState(false)

  function closeModalSix() {
    setIsOpenSix(false)
  }

  let [isOpenSeven, setIsOpenSeven] = useState(false)

  function closeModalSeven() {
    setIsOpenSeven(false)
  }

  let [isOpenEight, setIsOpenEight] = useState(false)

  function closeModalEight() {
    setIsOpenEight(false)
  }

  const [modalData, setModalData] = useState('')



  return (
    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
      <div className="py-10">
        <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/1_girl/fengmian/fengmian.jpg' onClick={() => { setIsOpen(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. L</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/b_girl/fengmian/fengmian.jpg' onClick={() => { setIsOpenTwo(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. B</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-fit' alt="" src='websites/before_after/c_girl/fengmian/fengmian.jpg' onClick={() => { setIsOpenThree(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. C</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/d_boy/fengmian/fengmian.jpg' onClick={() => { setIsOpenFour(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Mr. D</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/l_girl/fengmian/fengmian.jpg' onClick={() => { setIsOpenFive(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. L</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/l_girl2/fengmian/fengmian.jpg' onClick={() => { setIsOpenSix(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. L</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/s_girl/fengmian/fengmian.jpg' onClick={() => { setIsOpenSeven(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Ms. S</p>
          </li>
          <li
            className="relative rounded-md p-3 hover:bg-gray-100"
          >
            <img className='aspect-[6/5] w-full rounded-2xl object-cover' alt="" src='websites/before_after/t_boy/ba/first.jpg' onClick={() => { setIsOpenEight(true); setModalData(beforeafter.b_girl) }}></img>
            <p>Mr. T</p>
          </li>
        </ul>

      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.first_girl.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenTwo} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalTwo}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.b_girl.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalTwo}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenThree} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalThree}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.c_girl.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalThree}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenFour} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalFour}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.d_boy.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalFour}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenFive} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalFive}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.l_girl.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalFive}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenSix} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalSix}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.l_girltwo.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalSix}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenSeven} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalSeven}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.s_girl.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalSeven}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenEight} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalEight}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  <ul className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                    {beforeafter.t_boy.map((data) =>
                      <li className="relative rounded-md p-3 hover:bg-gray-100">
                        <img className='aspect-[4/5] w-full rounded ' src={data.source} alt=''></img>
                      </li>
                    )}

                    {/* {beforeafter.b_girl.map((object,i)=>(
                      <p key={i}>{object.id}</p>
                    ))} */}
                  </ul>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeModalEight}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div >


  )
}
