import { useState, useEffect } from "react";

export default function Example() {
    const images = [
        "websites/hero2.jpg",
        "websites/hero3.jpg",
        "websites/hero4.jpg",
        "websites/hero5.jpg",
        "websites/hero6.jpg",
        "websites/hero7.jpg",
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFade(true);
            }, 500);
        }, 3000);
        return () => clearInterval(interval);
    }, [images.length]);

    const handleDotClick = (index) => {
        setFade(false);
        setTimeout(() => {
            setCurrentImageIndex(index);
            setFade(true);
        }, 300);
    };

    return (
        <div className="relative transition-opacity overflow-hidden ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="relative z-20 lg:w-full lg:max-w-2xl">
                    <svg
                        className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                        <polygon points="0,0 90,0 50,105 0,105" />
                    </svg>

                    <div className="relative py-32 px-6 sm:py-40 lg:py-36 lg:px-8 lg:pr-48">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
                                Grateful Healthcare Malaysia
                            </h1>
                            <p className="mt-6 text-md leading-8 text-gray-600">
                                We are a professional Traditional Chinese Medicine (TCM) clinic based in Kuching, Sarawak, Malaysia. Our specialty lies in providing conservative exercise therapy for scoliosis, as well as treating various functional pain conditions, including frozen shoulder, headaches, stiff neck, dizziness, and other related issues. We have branches in Selangor and Johor.
                                <br></br>
                                Our company is dedicated to upholding the highest standards of professionalism and integrity, with a primary focus on promoting patient recovery and well-being.
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="/About"
                                    className="rounded-md bg-red-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    About Us
                                </a>
                                <a href="/Service" className="text-sm font-semibold leading-6 text-gray-900">
                                    Learn More <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 z-10 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    className={`aspect-[3/2] object-cover lg:aspect-auto mb-10 h-full transition-opacity duration-1000 ${fade ? 'opacity-100' : 'opacity-0'}`}
                    src={images[currentImageIndex]}
                    alt=""
                />

                {/* Dots for image navigation */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={`w-3 h-3 rounded-full ${currentImageIndex === index ? 'bg-red-600' : 'bg-gray-100 opacity-50'} focus:outline-none`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}