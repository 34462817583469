
export default function Example() {
    return (
      <div className="relative bg-white h-screen">
        <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
          <div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-4 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-3">
            <div className="mx-auto max-w-xl lg:mx-0">
              <h1 className="mt-24 text-4xl font-bold text-gray-900 sm:mt-10 sm:text-5xl">Our Headquaters
              </h1>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 mt-6">
                    <path d="M8.161 2.58a1.875 1.875 0 011.678 0l4.993 2.498c.106.052.23.052.336 0l3.869-1.935A1.875 1.875 0 0121.75 4.82v12.485c0 .71-.401 1.36-1.037 1.677l-4.875 2.437a1.875 1.875 0 01-1.676 0l-4.994-2.497a.375.375 0 00-.336 0l-3.868 1.935A1.875 1.875 0 012.25 19.18V6.695c0-.71.401-1.36 1.036-1.677l4.875-2.437zM9 6a.75.75 0 01.75.75V15a.75.75 0 01-1.5 0V6.75A.75.75 0 019 6zm6.75 3a.75.75 0 00-1.5 0v8.25a.75.75 0 001.5 0V9z" />
                </svg>
                <p className="mt-4 text-md text-gray-600">
                    903, Lorong Stutong B, Taman BDC 93350 Kuching, Sarawak, Malaysia
                </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="https://goo.gl/maps/fJSUZJg1ZC1wXUMt6"
                  className="rounded-md bg-red-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  Map
                </a>
              </div>
            </div>
          </div>
          <div className="relative lg:col-span-8 lg:-mr-8 xl:absolute xl:inset-0 xl:left-[40%] xl:mr-0 flex">
            <iframe title="framemap" className="w-full h-[400px] lg:h-[500px] xl:h-4/5 shadow-xl lg:rounded-l-3xl self-center" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.437319480957!2d110.36057601744385!3d1.5075459999999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31fba7dc7d5f2837%3A0x9d0db5baa7ec3b38!2sGrateful%20Healthcare%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1677125195944!5m2!1sen!2smy" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    )
  }
  