import Header from '../Components/Layout/Header.js'
import Hero from '../Components/About/Hero.js'
import Mission from '../Components/About/Mission.js'
import Team from '../Components/About/OurTeam.js'
import EveryLoc from '../Components/About/EveryLoc'
import Testi from '../Components/About/Testimonials'
import Footer from '../Components/Layout/Footer.js'
import License from '../Components/About/License.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      {/* <Mission /> */}
      <Team />
      <License />
      <EveryLoc />
      <Testi />
      <Footer />
    </>
  );
}