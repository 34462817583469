

const incentives = [
  {
    name: 'Adhesive Shoulder Capsulitis',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
    description: "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
  },
  {
    name: 'Numbness',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
    description: "If it breaks in the first 10 years we'll replace it. After that you're on your own though.",
  },
  {
    name: 'Internal Functionality Injury',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
    description:
      "If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though.",
  },
]

export default function Example() {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-bold  text-gray-900">
                Long's Osteopathy
              </h2>
              <p className="mt-4 text-gray-500">
                Based on the traditional Chinese medicine method of orthopedic and internal massage, combined with modern spinal physiological anatomy and biomechanics, and based on the pathological changes of small spinal joint subluxation, this is an effective method for treating spinal joint subluxation, intervertebral soft tissue strain, synovial impaction and intervertebral disc herniation. This technique is accurate, light, painless, safe and effective in treating both bone and soft tissue. The Long's Orthopedic Massage method is widely used in clinical practice, and is effective in treating various spinal cervical spondylosis, various lumbar pains, lumbar spondylolisthesis, lumbar disc herniation, lumbar muscle strain, acute and chronic lumbar sprains, etc.
              </p>
            </div>
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg bg-gray-100">
              <img
                src="websites/Treatment2.jpg"
                alt=""
                className="object-cover object-center"
              />
            </div>
          </div>
          {/* <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                </div>
            </div>
            ))}
        </div> */}
        </div>
      </div>
    </div>
  )
}
