const testimonials = [
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Kim Choo Seng',
      handle: 'SJK Chung Hua',
      imageUrl:
        'websites/Certificate/Cert1.png',
    },
  },
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Sungai Tapang',
      handle: 'SJK Chung Hua',
      imageUrl:
        'websites/Certificate/Cert2.png',
    },
  },
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Sibu',
      handle: 'SMK Wong Nai Siong',
      imageUrl:
        'websites/Certificate/Cert3.png',
    },
  },
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Kuching',
      handle: 'Hua Zong',
      imageUrl:
        'websites/Certificate/Cert4.png',
    },
  },
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Kuching',
      handle: 'Chung Hua Middle School No.3',
      imageUrl:
        'websites/Certificate/Cert5.png',
    },
  },
  {
    body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
    author: {
      name: 'Kuching',
      handle: 'Sunway College',
      imageUrl:
        'websites/Certificate/Cert6.png',
    },
  },
  // More testimonials...
]

export default function Example() {
  return (
    <div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-lg font-semibold leading-8  text-red-600">Testimonials</h2>
            <p className="mt-2 text-3xl text-gray-900 sm:text-4xl">
              Our <span className="font-bold text-red-900">Certification of Appreciation</span> from our client.
            </p>
          </div>

          <ul class="mt-10 pb-8 w-full flex gap-8 snap-x overflow-x-auto self-center">
            {testimonials.map((testimonial) => (
              <li class="snap-center">
                <div class="relative flex-shrink-0 max-w-[400px] h-[600px] overflow-hidden rounded-3xl">
                  <img src={testimonial.author.imageUrl} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />
                  <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

                  <div class=" relative h-96 w-[768px] p-12 flex flex-col justify-between items-start">
                    <div>
                      <p class="font-medium text-stone-50">{testimonial.author.name}</p>
                      <h2 class="w-2/3 mt-3 text-3xl font-semibold tracking-tight text-white">{testimonial.author.handle}</h2>
                    </div>

                    {/* <a href="#" class="px-4 py-3 text-sm font-medium bg-white rounded-lg text-slate-900"> browse</a> */}
                  </div>
                </div>
              </li>
            ))}
          </ul>


          {/* <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
            <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-2">
              {testimonials.map((testimonial) => (
                <div key={testimonial.author.handle} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                  <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                    <blockquote className="text-gray-900">
                      <p>{`“${testimonial.body}”`}</p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center justify-center gap-x-4">
                      <img className="h-100 w-72 bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                      <div>
                        <div className="font-semibold text-gray-900">{testimonial.author.name}</div>
                        <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
