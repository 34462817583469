const people = [
  {
    name: 'Mr. Toh Chee Hang',
    role: 'Professional & Certified Traditional Chinese Medicine',
    imageUrl: 'websites/Mrtoh.png',
    bio: `Mr. Toh is a certified Traditional Chinese Medicine (TCM) physician who obtained his practicing certificate in Traditional Chinese Medicine Therapy and Natural Therapy in Malaysia in 2011.
    
    - <strong>2016</strong>: Founded Grateful Healthcare Sdn. Bhd. and introduced Schroth scoliosis exercises & GBW braces in Malaysia.
    - <strong>2016</strong>: Earned SKM Diploma in Massage (Malaysia).
    - <strong>2017</strong>: Studied Long's cervical, thoracic, lumbar spine, and orthopedic techniques at Longji Health University, Guangzhou, China.
    - <strong>2018</strong>: Became a permanent member of the Kuching Traditional Chinese Medicine and Integrative Natural Medicine Association.
    - <strong>2018</strong>: Obtained the German Schroth SBP International Best Practice Diploma.
    - <strong>2018</strong>: Achieved Level 3 Diploma in Massage from JPK Malaysia.
    - <strong>2018</strong>: Graduated from Longji Health, Guangzhou, with a Long's Technique Certificate.
    - <strong>2019</strong>: Attended pelvic function assessment and Long’s spinal exercise programs in Guangzhou.
    - <strong>2019</strong>: Completed the 7D Therapy for spinal scoliosis learning program at Longji Health.
    - <strong>2019</strong>: Received the German Schroth GBW Best Practice Diploma.
    - <strong>2020</strong>: Obtained his practicing physician certificate from the Sarawak Traditional Physicians and Alternative Medicine Association.
    - <strong>2021</strong>: Appointed as the General Agent for GBW in Malaysia by Dr. Hans-Rudolf Weiss, MD, the third-generation successor of Schroth.
    - <strong>2021</strong>: Designated SBP Course Manager by Dr. Budi Sugiarto Widjaja, MD, the Southeast Asia General Manager of Schroth.`
  }
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">Our Director</h2>
        </div>
        <ul role="list" className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
          {people.map((person) => (
            <li key={person.name} className="flex flex-col gap-16 xl:flex-row">
              <img className="h-100 w-72 flex-none rounded-2xl object-cover" src={person.imageUrl} alt={person.name} />
              <div className="flex-auto">
                <h3 className="text-lg font-semibold leading-8 text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: person.bio }}></p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
