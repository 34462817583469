export default function Example() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Long's Butterfly-shaped Adjustable Pillow.</h2>
            <p className="mt-6 text-md leading-8 text-gray-600">
              Designed and produced by a well-known Chinese spine expert following medical principles, this product has multiple national patents. From a more professional perspective, it helps users effectively protect their cervical spine, establish a good sleeping posture, and improve the quality of sleep.

              The pillow is measured, and health is obtained from sleeping on it.
            </p>


          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="websites/Pillow.png"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-white object-contain"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
