export default function Example() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">GBW Brace（Gensingen brace）From Germany </h2>
            <p className="mt-6 text-md leading-8 text-gray-600">
              The most advanced orthopedic brace for scoliosis available. The orthopedic effect is amazing, and the scoliosis is corrected to the maximum extent from the three-dimensional space. It ultimately corrects scoliosis by guiding the body through corrective movements (the same principle as the German Schroth orthopedic gymnastics). It places the patients body in an overcorrected position, which is the opposite of the patients pathological curvature, and therefore effectively reduces scoliosis while changing other undesirable postures.
            </p>


          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="websites/Brace.png"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-white object-contain"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
