const tiers = [
  {
    name: 'East Malaysia (Headquaters)',
    href: 'https://goo.gl/maps/zVmJK91pqihPXeam9',
    location: 'Kuching',
    description: '903, Lorong Stutong B, Taman BDC 93350 Kuching, Sarawak, Malaysia',
  },
  {
    name: 'West Malaysia (M-2)',
    href: 'https://goo.gl/maps/YhR2ja9EX2UYAyjm9',
    location: 'Johor (Appointment)',
    description: 'No. 81A, Jalan Rawa, Taman Perling, 81200 Johor Bahru, Malaysia (Call for appointment)',
  },
  {
    name: 'West Malaysia (M-3)',
    href: 'https://goo.gl/maps/kYiT39wBTzkChipWA',
    location: 'Selangor (Appointment)',
    description: 'No.62B,Jalan SS2/66, 47400 Petaling Jaya, Selangor D.E, Malaysia (Call for appointment)',
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-4">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-red-600">Our Branches</h2>
          <p className="mt-2 text-4xl font-bold  text-gray-900 sm:text-5xl">
            Ahead to your nearest branch.
          </p>
        </div>
        {/* <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
          Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in.
          Explicabo id ut laborum.
        </p> */}
        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-2xl grid-cols-1 gap-y-16 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-2 lg:divide-y-0 lg:divide-x xl:-mx-4">
            {tiers.map((tier) => (
              <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id={tier.id} className="text-base font-semibold leading-7 text-gray-900">
                  {tier.name}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-3xl font-bold  text-gray-900">{tier.location}</span>
                </p>
                <p className="mt-10 text-sm leading-6 text-gray-900">{tier.description}</p>
                <div className=" mt-4 h-px bg-gray-300" />
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className="mt-10 block rounded-md bg-red-800 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  target="blank"
                >
                  Map
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
