import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What is scoliosis?",
    answer:
      "Scoliosis is a three-dimensional deformity of the spine characterized by an abnormal curvature in the coronal, sagittal, and horizontal planes. It can be diagnosed as scoliosis if the Cobb angle of a patient exceeds 10° on an X-ray taken in a standing position. Scoliosis causes rotation of the spinal joints, further exacerbating the degree of curvature.",
  },
  {
    question: "The harm of scoliosis to individuals?    ",
    answer:
    "Although scoliosis in adolescents usually does not pose a life-threatening risk, it can have serious implications for their entire lives. In terms of appearance, scoliosis can affect the development of height, resulting in stunted growth in children. For girls, it can lead to breast asymmetry and protrusion of one side of the ribs, causing feelings of inferiority and social withdrawal. Additionally, scoliosis typically occurs in the thoracic and lumbar regions, and severe curvature can result in rotational deformity of the chest and reduced chest volume, impacting cardiovascular and pulmonary development and causing symptoms such as decreased endurance, palpitations, and shortness of breath.",
  },
  {
    question: "What is idiopathic scoliosis?    ",
    answer:
      "Idiopathic scoliosis refers to the most common type of scoliosis with an unknown cause. It has a prevalence of 2% to 5% and carries a progression risk of 42% to 49%. Idiopathic scoliosis typically occurs during specific growth periods in adolescents and presents as a natural development of the spine curving abnormally. It occurs without the presence of other diseases or symptoms. Idiopathic scoliosis is more prevalent in females, accounting for 90% more cases than in males. On the other hand, acquired scoliosis usually occurs during adolescence, and its exact causes are not yet clear. It is generally not solely attributed to incorrect sitting or sleeping positions or carrying backpacks incorrectly but is influenced by factors such as developmental abnormalities, neuro-muscular imbalances, hormonal disruptions, or impaired balance regulation.",
  },
  {
    question: "What is pathological scoliosis?",
    answer:
      "Pathological scoliosis refers to the spinal curvature caused by diseases, including functional scoliosis, neuromuscular scoliosis, and scoliosis associated with other conditions such as Marfan syndrome, Prader-Willi syndrome, tumors, etc. Prior to treatment, careful assessment and diagnosis are necessary for pathological scoliosis.",
  },
  {
    question: "Can scoliosis be left untreated?",
    answer:
      "Interventional treatment is necessary when scoliosis occurs during adolescence, as the curvature of the spine may worsen during the period of skeletal growth if left untreated. Treatment options typically include surgical intervention and conservative treatment. If the degree of scoliosis remains below 20 degrees in adulthood, it indicates that the condition is stable and further treatment is not required.",
  },
  {
    question: "What are the conservative treatment methods for scoliosis?",
    answer:
      "For the conservative treatment of scoliosis, especially in adolescent patients, the Schroth Method for scoliosis treatment from Germany is a commonly used approach. This method involves wearing effective GBW braces and engaging in targeted scoliosis-specific exercises (SBP exercises). During the treatment process, it is important to have patient cooperation and maintain good communication among parents, therapists, and the patient themselves to ensure optimal treatment outcomes.",
  },
  {
    question: "What is a high arch foot? How does it affect your life?",
    answer:
      "A high arch foot refers to an elevated or raised arch on the inner side (longitudinal arch) of the foot, causing excessive pressure on the inner area of the foot. This can result in foot pain, fatigue, and discomfort. The increased arch height in the foot can be caused by various factors, including genetics, muscle tightness, and improper gait or posture. Additionally, certain conditions such as plantar fasciitis and Achilles tendonitis can also contribute to an increased arch height. The treatment of a high arch foot should be based on its underlying cause. In some cases, especially when foot arch pain is severe, the use of arch support inserts or orthotics may be necessary to alleviate the pain. Physical therapy may also be recommended as part of the treatment plan.",
  },
  {
    question: "What is flat feet? How does it affect your life?",
    answer:
      "Flat feet is a condition where there is an abnormality in the structure of the feet, characterized by a lowered or reduced arch on the inside of the foot, leading to an increased contact area between the foot and the ground. It can be caused by various factors such as genetics, muscle and ligament laxity, insufficient muscle strength, and abnormal bone development. Flat feet can result in a lack of adequate support and stability in the foot during walking and physical activities. Common symptoms include foot pain, arch fatigue, ankle and knee discomfort, and abnormal gait. In some cases, flat feet can also contribute to other issues such as foot deformities, toe misalignment, and hip joint pain. Treatment for flat feet should be tailored to the individual's symptoms and the severity of the condition. Options may include wearing arch-supporting insoles, undergoing physical therapy and rehabilitation exercises to strengthen the foot muscles and improve arch support.",
  },
  {
    question: "What is functional pain?",
    answer:
      "Functional pain typically arises due to imbalances in the body's functioning. When the musculoskeletal system is unable to operate in a balanced manner, muscle compensation instability can occur, leading to muscle strain, joint misalignment, and related issues such as muscle tension and ligament calcification, resulting in pain symptoms.",
  },
  // More questions...
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-10 sm:py-32 lg:pt-10 lg:pb-24 lg:px-8">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10  text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
