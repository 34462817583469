import Header from '../Components/Layout/Header.js'
import Hero from '../Components/Product/Hero'
import Product from '../Components/Product/Product'
import Product2 from '../Components/Product/Product2'
import Product3 from '../Components/Product/Product3'
import Product4 from '../Components/Product/Product4.js'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Product />
      <Product3 />
      <Product4 />
      <Footer />
    </>
  );
}