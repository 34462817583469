const callouts = [
    {
        name: 'Borang F',
        description: 'Traditional & Complimentary Medicine Act',
        imageSrc: 'websites/License/license1.jpg',
        imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
        href: '#',
    },
    {
        name: 'Borang D',
        description: 'Traditional & Complimentary Medicine Act',
        imageSrc: 'websites/License/license2.jpg',
        imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
        href: '#',
    },
    {
        name: 'Borang K',
        description: 'Traditional & Complimentary Medicine Act',
        imageSrc: 'websites/License/license4.jpg',
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '#',
    },
]

export default function Example() {
    return (
        <div className="">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <h2 className="text-2xl font-bold text-gray-900">Company License</h2>

                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        {callouts.map((callout) => (
                            <div key={callout.name} className="group relative">
                                <img
                                    alt={callout.imageAlt}
                                    src={callout.imageSrc}
                                    className="w-full rounded-lg bg-white object-cover group-hover:opacity-75"
                                />
                                <h3 className="mt-6 text-sm font-semibold text-gray-900">
                                    <span className="absolute inset-0" />
                                    {callout.name}
                                </h3>
                                <p className="text-base text-gray-500">{callout.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
