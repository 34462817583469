import Header from '../Components/Layout/Header.js'
import Maintenance from '../Components/Maintenance/Maintenance'
import Hero from '../Components/Comparison/Scoliosis/Hero.js'
import Content from '../Components/Comparison/Scoliosis/Content.js'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
        <Header />
        {/* <Maintenance/> */}
        <Hero />
        <Content/>
        <Footer/>
    </>
  );
}