import Header from '../Components/Layout/Header.js'
import Maintenance from '../Components/Maintenance/Maintenance'
// import First from '../Components/Journey/First.js'
// import Photos from '../Components/Journey/Photos'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
        <Header />
        <Maintenance/>
          {/* <First />
          <Photos /> */}
        <Footer/>
    </>
  );
}