import Header from '../Components/Layout/Header.js'
import Content from '../Components/Contact/Content.js'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
        <Header />
          <Content />
        <Footer/>
    </>
  );
}