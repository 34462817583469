export default function Example() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Capron Shoe Insole</h2>
            <p className="mt-6 text-md leading-8 text-gray-600">
              Our shoe insole is from the French brand Capron, which has been producing self-made insoles and providing services around the world since 1974. These insoles are moderately priced and highly competitive in the market. Choosing the right insole is crucial for patients with foot arch abnormalities, and our insoles can provide high-quality solutions and effective relief and support for your foot problems. Additionally, our insoles come with a 2-year free repair service, giving you more peace of mind when making a purchase.
            </p>


          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="websites/shoe.png"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-white object-contain"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
