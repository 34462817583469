export default function Example() {
    return (
      <div className="bg-red-100">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32  text-center">
          <h2 className="text-4xl font-bold text-gray-900">
            Accomplishments
            <br />
            Before & After Results of our Patients
          </h2>
          <p class="mt-4 text-md">The following is only shown for some of the patients.</p>

          {/* <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </a>
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div> */}
        </div>
      </div>
    )
  }
  