import { UsersIcon, HandRaisedIcon, DocumentCheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Conservative exercise of adolescent scoliosis.',
    description:
      "The exercise methods for scoliosis mainly include surgical and conservative exercises. Compared to the risky surgical exercise, we choose the conservative exercise approach using the German Schroth Method, which includes specific SBP spinal corrective exercises and appropriate GBW braces. This exercise method can significantly improve the patient's scoliosis condition and avoid surgical risks.",
    icon: UsersIcon,
  },
  {
    name: 'Muscle and bone injury physical therapy. ',
    description: 'We will assess your body posture, bones, joints, muscles, fascia, and other tissues, and provide targeted physical therapy and manual therapy exercise based on the assessment results. Our professional manual therapy can relieve functional pain such as lower back pain, dizziness, stiff neck, and frozen shoulder. In addition, therapeutic massage for muscle and bone injuries can also improve blood circulation, alleviate muscle and joint pain, promote soft tissue healing, and accelerate recovery and exercise of the body.    ',
    icon: HandRaisedIcon,
  },
  {
    name: 'The first self-made insole with the best cost-performance ratio in Malaysia.',
    description: "Abnormal foot arches, such as flat feet or high arches, can lead to lower limb pain and even cause back pain and other disorders. The structure of the foot determines body balance and posture, and flat feet and high arches increase the risk of back pain. A comfortable shoe insole can relieve functional pain in the body, making walking easier and more comfortable!    ",
    icon: DocumentCheckIcon,
  },
]

export default function Example() {
  return (
    <div className="overflow-hidden bg-white sm:py-2">
      <div className="mx-auto max-w-7xl sm:py-28 px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-red-600">About Us</h2>
              <p className="mt-2 text-3xl font-bold  text-gray-900 sm:text-4xl">Who we are?</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Talents in Scoliosis exercise with Traditional Medical exercise.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-red-800" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <br></br>
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src="websites/treatment2.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
