import Header from '../Components/Layout/Header.js'
import First from '../Components/Services/First.js'
import Second from '../Components/Services/Second.js'
import Third from '../Components/Services/Third.js'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
      <Header />
        <First/>
        <Second/>
        <Third/>
        <Footer />
    </>
  );
}