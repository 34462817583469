const offers = [
  { name: 'Contact Number', description: '+60 13 681 2301', href: '#' },
  { name: "Address", description: '903, Lorong Stutong B, Taman BDC, Kuching', href: '#' },
  { name: "TCM", description: 'Mr. Toh', href: '#' },
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="flex flex-col border-b border-gray-200 lg:border-0">
        <nav aria-label="Offers" className="order-last lg:order-first">
          <div className="mx-auto max-w-7xl lg:px-8">
            <ul className="grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-3 lg:divide-y-0 lg:divide-x">
              {offers.map((offer) => (
                <li key={offer.name} className="flex flex-col">
                  <a
                    href={offer.href}
                    className="relative flex flex-1 flex-col justify-center bg-white py-6 px-4 text-center focus:z-10"
                  >
                    <p className="text-sm text-gray-500">{offer.name}</p>
                    <p className="font-semibold text-gray-900">{offer.description}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        <div className="relative">
          <div aria-hidden="true" className="absolute hidden h-full w-1/2 bg-gray-100 lg:block" />
          <div className="relative bg-gray-100 lg:bg-transparent">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8">
              <div className="mx-auto max-w-2xl py-24 lg:max-w-none lg:py-64">
                <div className="lg:pr-24">
                  <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl xl:text-6xl">Our History</h1>
                  <div className="mt-6 space-y-4 text-md text-gray-600">
                    <p><strong>2016:</strong> Registered as a rehabilitation and physiotherapy clinic in Sarawak.</p>
                    <p><strong>2017:</strong> Established our first physiotherapy clinic in Kuching, Sarawak.</p>
                    <p><strong>2021:</strong> Expanded operations with a second physiotherapy clinic in Johor.</p>
                    <p><strong>2023:</strong> Opened third physiotherapy clinics in Selangor.</p>
                    <p><strong>2025:</strong> Partnered with a clinic in Singapore.</p>
                  </div>
                  <p className="mt-6 text-md text-gray-600">
                    Our company is committed to upholding the highest standards of professionalism and integrity, with a primary focus on patient rehabilitation. We believe in making well-informed decisions and demonstrating unwavering dedication in our pursuit of excellence.
                  </p>
                  <div className="mt-12">
                    <a
                      href="/Service"
                      className="inline-block rounded-md border border-transparent bg-red-800 py-3 px-8 font-medium text-white hover:bg-red-700"
                    >
                      Our Services
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-48 w-full sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
            <img src="websites/service-all.jpg" alt="" className="h-full w-full object-cover object-center" />
          </div>
        </div>
      </div>
    </div>
  );
}