import Header from '../Components/Layout/Header.js'
import Hero from '../Components/Landing/Hero.js'
import About from '../Components/Landing/About.js'
import Services from '../Components/Landing/Services'
import Location from '../Components/Landing/Location.js'
import Blog from '../Components/Landing/Blog.js'
import FAQ from '../Components/Landing/Faq.js'
import Footer from '../Components/Layout/Footer.js'

import * as React from 'react';

export default function Home() {
  return (
    <>
      <Header />
        <Hero />
        <About/>
        <Services/>
        <Location/>
        {/* <Blog/> */}
        <FAQ />
        <Footer />
    </>
  );
}